<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    inverted?: boolean;
  }>(),
  {
    inverted: false,
  },
);

const emit = defineEmits(["togglePanel"]);
</script>

<template>
  <div
    class="h-full w-[20] flex items-center justify-end cursor-pointer"
    @click="emit('togglePanel')"
  >
    <svg
      width="9"
      height="7"
      viewBox="0 0 9 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      :class="{
        'transform rotate-180': props.inverted,
      }"
    >
      <path
        d="M5.26799 6.07909C4.86821 6.55848 4.13179 6.55848 3.73201 6.0791L0.238902 1.89046C-0.304217 1.2392 0.158881 0.249999 1.00689 0.249999L7.99311 0.25C8.84112 0.25 9.30422 1.2392 8.7611 1.89046L5.26799 6.07909Z"
        fill="black"
      />
    </svg>
  </div>
</template>
