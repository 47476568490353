<script lang="ts" setup>
import { useCartStore } from "~/stores/cartStore";
import {
  addToCart as sendAddToCartEvent,
  removeFromCart as sendRemoveFromCartEvent,
} from "~/composables/useGoogleAnalytics";

const cartStore = useCartStore();
const { $api } = useNuxtApp();

const props = defineProps<{
  cartItem: Record<string, any>;
}>();
const config = useRuntimeConfig();
const {
  productPageUrl,
  totalStockOfVariant,
  getVariantByProperty,
  getResizedImageUrl,
} = useHelpers();

const masterProductRef: Record<string, any> = ref();
const hasMasterProductDataRef = ref(false);
const quantityRef = ref(props.cartItem.cartItem.quantity);
const maxQuantityRef = computed(() => {
  if (variantRef.value) {
    return totalStockOfVariant(variantRef.value);
  }

  return 0;
});

const variantRef = computed(() => {
  return getVariantByProperty(
    masterProductRef.value,
    "id",
    props.cartItem.product.id,
  );
});

const cartItemImage = () => {
  let path = null;

  const productImages = masterProductRef.value.products.find(
    (item) => item.product.id === props.cartItem.product.id,
  )?.images;
  if (productImages?.length) {
    path = productImages[0];
  } else if (masterProductRef.value.images.length) {
    path = masterProductRef.value.images[0];
  }

  if (path) {
    return getResizedImageUrl(path, 150);
  }
};

const productPage = () => {
  if (masterProductRef.value?.sku)
    return productPageUrl(masterProductRef.value);
};

const deleteItem = () => {
  $api("cart-item-quantity", {
    method: "PATCH",
    body: {
      code: localStorage.getItem("webshop.cart.code"),
      product: props.cartItem.product.id,
      quantity: 0,
    },
  })
    .then(() => {
      sendRemoveFromCartEvent(
        masterProductRef.value,
        variantRef.value,
        props.cartItem.cartItem.quantity,
      );

      cartStore.fetchCartData();
    })
    .catch(() => {
      // TODO: show an informative error message to user
    });
};

// -----------------------
// vue events
// -----------------------
onMounted(async () => {
  // console.log("BasketItem onMounted", props.cartItem);
  const masterProduct = await useMeilisearch(
    config.public.meilisearchIndexMasterproducts,
    {
      filter: ["sku = " + props.cartItem.masterProduct.sku],
    },
  );
  // await nextTick();
  // console.log("mp", masterProduct);
  if (masterProduct.length === 1) {
    masterProductRef.value = masterProduct[0];
    hasMasterProductDataRef.value = true;
  }
  // console.log(masterProductRef.value)
});

watch(
  () => props.cartItem,
  () => {
    // if the quantity is modified from other source and doesn't match the quantityRef in this component
    // we update the local quantityRef. Eg when on checkout page we change the quantity from the basket panel,
    // we need to update the widget on the page too
    if (quantityRef.value !== props.cartItem.cartItem.quantity) {
      quantityRef.value = props.cartItem.cartItem.quantity;
    }
  },
);

watch(
  () => quantityRef.value,
  (newQuantity, oldQuantity) => {
    $api("cart-item-quantity", {
      method: "PATCH",
      body: {
        code: localStorage.getItem("webshop.cart.code"),
        product: props.cartItem.product.id,
        quantity: newQuantity,
      },
    })
      .then(() => {
        if (newQuantity < oldQuantity) {
          sendRemoveFromCartEvent(
            masterProductRef.value,
            variantRef.value,
            oldQuantity - newQuantity,
          );
        }

        cartStore.fetchCartData();

        if (newQuantity > oldQuantity) {
          sendAddToCartEvent(
            masterProductRef.value,
            variantRef.value,
            newQuantity - oldQuantity,
          );
        }
      })
      .catch(() => {
        // TODO: show an informative error message to user and restore old quantity
      });
  },
);
</script>

<template>
  <div
    v-if="hasMasterProductDataRef"
    class="flex flex-wrap text-xs mb-1 border-t border-secondary00 pt-4 mb-4"
  >
    <div class="w-3/12 pr-3 pb-3">
      <NuxtLink :to="productPage()"><img :src="cartItemImage()" /></NuxtLink>
    </div>
    <div class="w-9/12">
      <div class="flex flex-wrap mb-2">
        <div class="w-8/12">
          <div class="leading-5" style="font-size: 10px">
            <div class="text-xs font-semibold mb-2">
              <NuxtLink :to="productPage()"
                ><span class="text-black">{{
                  masterProductRef?.full_name
                }}</span></NuxtLink
              >
            </div>

            <p v-if="masterProductRef && masterProductRef.products?.length">
              <span class="font-semibold">{{ $t("size") }}</span
              >:
              {{
                masterProductRef.products?.find(
                  (obj) => obj.product.id === props.cartItem.product.id,
                ).product.product_meret_property
              }}<br />
              <span class="font-semibold">{{ $t("color") }}</span
              >:
              {{
                masterProductRef.products.find(
                  (obj) => obj.product.id === props.cartItem.product.id,
                ).product.product_szin_property
              }}<br />
              <!--
              {{ $t("palette_label") }}:
              {{
                $t(
                  "palette." +
                    masterProductRef.products.find(
                      (obj) => obj.product.id === props.cartItem.product.id,
                    ).product.product_paletta_property,
                )
              }}<br />
              -->
            </p>
          </div>
        </div>
        <div class="w-4/12 flex justify-end">
          <i
            class="m-icon-bin text-14 ml-auto cursor-pointer"
            :title="$t('delete_cart_item')"
            @click="deleteItem"
          ></i>
        </div>
      </div>
      <div class="flex flex-wrap justify-end">
        <div class="w-8/12">
          <!--
            :max="maxQuantityRef"
            -->
          <BaseNumber
            v-model="quantityRef"
            :min="1"
            :max="maxQuantityRef"
          ></BaseNumber>
        </div>
        <div class="w-4/12 text-right text-base font-semibold self-end">
          {{ usePriceFormatter(props.cartItem.cartItem.price) }}
        </div>
      </div>
    </div>
  </div>
</template>
